.container-dashboard {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  word-wrap: break-word;
}

header {
  width: 100%;
  height: 60px;
  background-color: rgb(172, 26, 47);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.selector {
  height: 36px;
  background-color: white;
}

.spinner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
