.modal-header {
  background-color: rgb(172, 26, 47);
  color: white;
  text-align: center;
}

.text-modal {
  height: 100px;
}

.input-text {
  padding: 10px;
  border: 0;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.enlace-descarga {
  margin: 0 20px 0 20px;
  width: 75%;
  text-decoration: none;
  text-align: center;
  background-color: black;
  color: whitesmoke;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid black;
  border-radius: 10px;
}

.sticky-content {
  background-color: rgb(172, 26, 47);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.button-line {
  border: solid 2px black;
}
